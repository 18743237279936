/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import useStyles from './styles';

const Privacy = () => {
  const { dataJson } = useStaticQuery(
    graphql`
      {
        dataJson {
          privacy {
            title
            subtitle
            subsubtitle
            text
            contactAndEmail
          }
          about {
            address
            email {
              url
              name
            }
          }
        }
      }
    `
  );
  const classes = useStyles();
  const { privacy, about } = dataJson;

  return (
    <div className={classes.container}>
      {privacy.map(({ title, subtitle, subsubtitle, text, contactAndEmail }, index) => (
        <Fragment key={index}>
          {title && <h2 className={classes.h2}>{title}</h2>}
          {subtitle && <h3 className={classes.h3}>{subtitle}</h3>}
          {subsubtitle && <h4 className={classes.h4}>{subsubtitle}</h4>}
          {text &&
            text.map((str, idx) => (
              <p key={idx} className={classes.text}>
                {str}
              </p>
            ))}
          {contactAndEmail && (
            <p className={classes.text}>
              {about.address.map((str, idx) => (
                <span key={idx}>{str}</span>
              ))}
              <a href={about.email.url} rel="noopener noreferrer" target="_blank">
                {about.email.name}
              </a>
            </p>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Privacy;
