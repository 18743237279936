import { createUseStyles } from 'react-jss';

export default createUseStyles(({ spacing, text }) => ({
  container: {
    '@media (min-width: 1100px) and (max-width: 1600px)': {
      width: spacing * 60,
    },
  },
  h2: {
    ...text.dropDown,
    margin: 0,
  },
  h3: {
    ...text.dropDown,
    margin: 0,
  },
  h4: {
    ...text.dropDown,
    margin: 0,
  },
  text: {
    ...text.main,
    margin: [0, 0, '1em', 0],
    '&>*': {
      display: 'block',
      width: 'fit-content',
    },
    '&>span': text.main,
    '&>a': text.link,
  },
}));
